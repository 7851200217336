<template>
    <Cutaway ref="refCutaway">
        <template v-for="(reveal, index) in revealsFiltered" :key="`${reveal.type}-${index}`">
            <!-- <component :is="reveal.type" :key="reveal.type" /> -->

            <TeamInfo
                ref="refReveals"
                v-if="reveal.type === 'team-info'"
                :teamName="card.teamName"
                :teamBadge="card.teamBadge"
                :teamYear="card.teamYear"
            />
            <PlayerInfo
                ref="refReveals"
                v-if="reveal.type === 'player-info'"
                :playerName="card.playerName"
                :playerNumber="card.playerNumber"
            />
            <CardInfo
                ref="refReveals"
                v-if="reveal.type === 'card-info'"
                :text="card.parallel"
                :serialNumber="card.serialNumber"
                :serialTotal="card.serialTotal"
                :gradeBadge="card.gradeBadge"
            />
            <CutawayA ref="refReveals" v-if="reveal.type === 'cutaway-a'" v-bind="reveal.data" />
            <CutawayB ref="refReveals" v-if="reveal.type === 'cutaway-b'" v-bind="reveal.data" />
            <CutawayC ref="refReveals" v-if="reveal.type === 'cutaway-c'" v-bind="reveal.data" />
            <LightsDown
                ref="refReveals"
                v-if="reveal.type === 'lights-down'"
                @start="onLightsDownStart"
            />
        </template>
    </Cutaway>
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';

    import gsap from '@resn/gsap';

    import CardInfo from './modules/CardInfo/CardInfo.vue';
    import Cutaway from './modules/Cutaway/Cutaway.vue';
    import CutawayA from './modules/Cutaway/CutawayA.vue';
    import CutawayB from './modules/Cutaway/CutawayB.vue';
    import CutawayC from './modules/Cutaway/CutawayC.vue';
    import LightsDown from './modules/LightsDown/LightsDown.vue';
    import PlayerInfo from './modules/PlayerInfo/PlayerInfo.vue';
    import TeamInfo from './modules/TeamInfo/TeamInfo.vue';

    const props = defineProps({
        reveals: {
            default: [
                { type: 'card-info' },
                { type: 'player-info' },
                // { type: 'team-info' },
                // { type: 'cutaway-a' },
                // { type: 'lights-down' },
            ],
        },
        card: { default: {} },
    });

    const refCutaway = ref(null);
    const refReveals = ref([]);

    const visible = ref(true);
    const cutawayVisible = ref(false);

    const revealsFiltered = computed(() => {
        return props.reveals.filter((reveal) => {
            switch (reveal.type) {
                case 'team-info':
                    return !!props.card.teamName;
                case 'player-info':
                    return !!props.card.playerName;
                case 'card-info':
                    return !!props.card.gradeBadge;

                default:
                    return true;
            }
        });
    });

    watch(cutawayVisible, (val) => {
        if (val) refCutaway.value.show();
        else refCutaway.value.hide();
    });

    const show = () => {
        visible.value = true;

        const tl = new gsap.timeline({});

        revealsFiltered.value.forEach((data, index) => {
            const reveal = refReveals.value[index];
            const isCutaway = data.type.includes('cutaway');
            const isLast = index === revealsFiltered.value.length - 1;

            const label = `reveal-${index}`;
            tl.addLabel(label);

            if (isCutaway) tl.add(() => (cutawayVisible.value = true), label);

            tl.add(reveal.show(), label);

            if (!isCutaway || isLast) tl.add(() => (cutawayVisible.value = false), '-=1.5');
        });

        const last = revealsFiltered.value[revealsFiltered.value.length - 1];
        const completeDelay = last ? (last.type.includes('cutaway') ? '-=0.8' : '-=0.6') : 0;

        tl.add(() => emit('revealComplete'), completeDelay);
    };

    const onLightsDownStart = () => {
        emit('startLightsDown');
    };

    defineExpose({ show });

    const emit = defineEmits(['revealComplete', 'revealStepUpdate', 'startLightsDown']);
</script>
