import glslToRadians from '@resn/gozer-glsl/math/to-radians.glsl';

import glslScreen from '@/libs/lygia/color/blend/screen.glsl';
import glslFill from '@/libs/lygia/draw/fill.glsl';
import glslStroke from '@/libs/lygia/draw/stroke.glsl';
import glslSnoise from '@/libs/lygia/generative/snoise.glsl';
import glslRotate from '@/libs/lygia/space/rotate.glsl';
import { createNoiseFromTexture2 } from '~/libs/three/noise-texture';

export const wipeChunks = {
    uniforms: () => ({
        uWipeAngle: { value: 0 },
        uRevealPr: { value: 0 },
    }),
    funcs: /* glsl */ `
        uniform float uWipeAngle;
        uniform float uRevealPr;
        
        ${glslToRadians}
        ${glslSnoise}
        ${glslRotate}
        ${glslStroke}
        ${glslFill}
        ${glslScreen}
        ${createNoiseFromTexture2}

        vec4 wipe(vec2 vUv, float wipeAngle, float revealPr, vec3 color1, vec3 color2, vec3 color3, sampler2D uNoise, float time) {

            // Wipe Mask -----------------------------------
            float angle = toRadians(wipeAngle);
            vec2 uv = rotate(vUv, angle);

            float progressIn = mix(-0.5, 1.8, revealPr);

            float sdf = uv.x;
            float noiseScale = 0.15;
            vec2 uvNoise = vUv * vec2(1.0, 2.0);
            // float noise = texture2D(uNoise, uvNoise).r;
            float noise = createNoiseFromTexture(uNoise, uvNoise, time * 0.3, noiseScale, 0.0);
            // float noise = snoise(vec3(vUv * 0.8, uTime * 0.2)) * 0.5 + 0.5;
            // float noise = 0.0;

            // Wipe 1
            float waveyWipe1 = fill(sdf + noise * 0.1, progressIn, 0.05);
            vec3 waveyWipeColor = color1 * waveyWipe1;

            // Wipe 2
            float wavey2Sdf = sdf - 0.05 + noise * 0.2;
            float wipe2EdgeThickness = mix(0.01, 0.1, smoothstep(0.8, 0.0, uv.y));
            float waveyWipe2 = fill(wavey2Sdf, progressIn, wipe2EdgeThickness);// * 0.2;

            // Wavey Line Bg
            float lineBgSdf = sdf + noise * 0.1;
            float lineBgEdgeWidth = mix(0.3, 0.4, smoothstep(1.0, 0.0, uv.y));
            float lineBgEdgeThickness = mix(0.2, 0.3, smoothstep(1.0, 0.0, uv.y));
            float lineBg = stroke(lineBgSdf, progressIn, lineBgEdgeWidth, lineBgEdgeThickness);
            vec3 lineBgColor = color2 * lineBg * 0.1;

            // Wavey Line 1
            float line1Sdf = sdf - 0.02 + noise * 0.1;
            float line1EdgeWidth = mix(0.06, 0.2, smoothstep(1.0, 0.0, uv.y));
            float line1EdgeThickness = mix(0.01, 0.1, smoothstep(1.0, 0.0, uv.y));
            float line1 = stroke(line1Sdf, progressIn, line1EdgeWidth, line1EdgeThickness);
            vec3 line1Color = color2 * line1;

            // Wavey Line 2
            float line2Sdf = sdf - 0.05 + noise * 0.13;
            float line2EdgeWidth = mix(0.07, 0.2, smoothstep(1.0, 0.0, uv.y));
            float line2EdgeThickness = mix(0.005, 0.03, smoothstep(1.0, 0.0, uv.y));
            float line2 = stroke(line2Sdf, progressIn, line2EdgeWidth, line2EdgeThickness);
            vec3 line2Color = color3 * line2 * 1.0;

            float waveyWipeFinal = waveyWipe1 + waveyWipe2 * 0.2;
            vec3 lineColor = blendScreen(lineBgColor, line1Color);
            lineColor = blendScreen(lineColor, line2Color);

            return vec4(lineColor, waveyWipeFinal);
            // return vec4(vec3(0.0), waveyWipeFinal);
        }
    `,
};
