<template>
    <div></div>
</template>

<script setup>
    import { Color, EquirectangularReflectionMapping, Vector2 } from 'three';
    import { inject, onMounted, ref, watch, watchEffect } from 'vue';

    import { randomInt } from '@resn/gozer-math';
    import { usePane, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import SlabMaterial2 from '../materials/SlabMaterial2';
    import { useBloom } from '~/composables/useBloom';
    import { LIGHTS_DOWN_REVEAL_START } from '~/core/constants';
    import { loadBitmapTexture } from '~/libs/three/loadBitmapTexture';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const props = defineProps({
        object: { defaut: null },
        active: { defaut: false },
        preRevealSticker: { defaut: '' },
        colorPalette: { defaut: [] },
        innerCardTexture: { defaut: null },
    });

    const { inProgress, revealPr, heartbeatPr, wipeAngle, lightAngle, depthOffset, time } =
        inject('card');

    const visible = ref(true);

    const uRes = new Vector2();
    useViewportResize(({ width, height }) => {
        uRes.set(width, height);
    }, true);

    const color = ref('white');
    const ior = ref(0);
    // const dispersionAmount = ref(0.04); // for vUv coord
    const dispersionAmount = ref(0.002); // for screen space coord

    const colors = [
        ['#87b2ff', '#bd8ef6'],
        ['#ffd987', '#b96954'],
        ['#68a4ff', '#54b996'],
        ['#68ffd1', '#92fc70'],
    ];
    const rColorIndex = randomInt(0, colors.length - 1);
    const preRevealColor1 = ref(colors[rColorIndex][0]);
    const preRevealColor2 = ref(colors[rColorIndex][1]);

    const bloom = useBloom();

    onMounted(() => {
        if (bloom.pass?.value) bloom.pass.value.selection?.add(object);

        setTexture(props.preRevealSticker);
    });

    const material = new SlabMaterial2({
        uniforms: {
            uTime: 0,
            uAlpha: 1,
            uInProgress: 0,
            uIor: ior.value,
            uCardTexture: props.innerCardTexture,
            uAlphaMask: null,
            uOutline: null,
            uNoise: null,
            uWhiteNoise: null,
            tMatcap: null,
            uEnvMap: null,
            uPreRevealSticker: null,
            uStickerAspect: 1,
            uColorPalette: props.colorPalette,
            uDispersionAmount: dispersionAmount.value,
            uMatcapRotation: lightAngle.value,
            uPreRevealColor1: new Color(preRevealColor1.value),
            uPreRevealColor2: new Color(preRevealColor2.value),
            uDepthOffset: 0,
            uHeartbeatPr: 0,
            uLightsDownPr: 0,
            uLightsDownRevealPr: 0,
            uRes,
        },
        options: {
            // blending: AdditiveBlending ,
            // side: DoubleSide,
        },
    });

    const object = props.object;
    object.material = material;
    object.renderOrder = 1;

    useGlobalAssets(({ data }) => {
        data.matcapDiffuse.flipY = true;
        data.envMap.mapping = EquirectangularReflectionMapping;

        material.uniforms.uAlphaMask.value = data.cardMask;
        material.uniforms.uOutline.value = data.cardOutline;
        material.uniforms.tMatcap.value = data.matcapDiffuse;
        material.uniforms.uWhiteNoise.value = data.noiseWhite;
        material.uniforms.uNoise.value = data.pNoise;
        material.uniforms.uEnvMap.value = data.envMap;
    });

    const lightsDownPr = ref(0);
    const lightsDownRevealPr = ref(0);

    const startLightsDown = () => {
        const tl = gsap.timeline({
            onComplete: () => {
                lightsDownPr.value = 0;
                lightsDownRevealPr.value = 0;
            },
        });

        tl.to(lightsDownPr, { value: 1, duration: 1 });
        tl.to(lightsDownRevealPr, { value: 1, duration: 5 }, LIGHTS_DOWN_REVEAL_START);
        return tl;
    };

    // Function to set texture
    const setTexture = (url) => {
        const { uPreRevealSticker, uStickerAspect } = material.uniforms;
        uPreRevealSticker.value?.dispose();

        if (!url) return;

        loadBitmapTexture(url, { flipY: true }).then((texture) => {
            uPreRevealSticker.value = texture;
            uStickerAspect.value = texture.image.width / texture.image.height;
        });
    };

    watch(() => props.preRevealSticker, setTexture);

    watchEffect(() => {
        material.uniforms.uIor.value = ior.value;
        material.uniforms.uRevealPr.value = revealPr.value;
        material.uniforms.uWipeAngle.value = wipeAngle.value;
        material.uniforms.uMatcapRotation.value = lightAngle.value;
        material.uniforms.uDispersionAmount.value = dispersionAmount.value;
        material.uniforms.uPreRevealColor1.value.set(preRevealColor1.value);
        material.uniforms.uPreRevealColor2.value.set(preRevealColor2.value);
        material.uniforms.uTime.value = time.value;
        material.uniforms.uDepthOffset.value = depthOffset.value;
        // material.uniforms.uAlpha.value = inProgress.value;
        material.uniforms.uInProgress.value = inProgress.value;
        material.uniforms.uHeartbeatPr.value = heartbeatPr.value;
        // if (props.active) console.log('🚀 ~ watchEffect ~ revealPr.value:', inProgress.value);

        object.visible = visible.value;
    });

    watchEffect(() => {
        material.uniforms.uLightsDownPr.value = lightsDownPr.value;
        material.uniforms.uLightsDownRevealPr.value = lightsDownRevealPr.value;
    });

    defineExpose({ startLightsDown });

    usePane(
        [
            {
                name: 'dispersionAmount',
                value: dispersionAmount,
                options: { min: 0, max: 0.01, step: 0.0001 },
            },
            { name: 'preRevealColor1', value: preRevealColor1 },
            { name: 'preRevealColor2', value: preRevealColor2 },
            { name: 'visible', value: visible },
            { name: 'color', value: color },
            { name: 'ior', value: ior, options: { min: 0, max: 5, step: 0.01 } },
        ],
        { title: 'Slab', expanded: false }
    );
</script>

<style lang="scss" scoped></style>
