<template>
    <Slab
        ref="refSlab"
        v-if="slabObject"
        :active="active"
        :object="slabObject"
        :colorPalette="colorPalette"
        :preRevealSticker="data.preRevealSticker"
        :innerCardTexture="innerCardFbo.texture"
    />
    <CardInner
        ref="refCardFront"
        v-if="cardFrontObject"
        :imgUrl="data.imageFront"
        :object="cardFrontObject"
        :active="active"
        name="Card Front"
        :layer="LAYER_CARD_INNER"
    />
    <CardInner
        ref="refCardBack"
        v-if="cardBackObject"
        :imgUrl="data.imageBack"
        :object="cardBackObject"
        :active="active"
        name="Card Back"
        :flip="true"
        :layer="LAYER_CARD_INNER"
    />
    <RevealsWrapper
        ref="refReveals"
        :card="data"
        :reveals="data.reveals"
        @revealStepUpdate="onRevealStepUpdate"
        @startLightsDown="onStartLightsDown"
        @revealComplete="onRevealComplete"
    ></RevealsWrapper>

    <Vortex ref="refVortex" :colorPalette="colorPalette"></Vortex>

    <!-- <Cutaway ref="refCutawayWrapper" v-if="data.preRevealCutawayText">
        <CutawayB ref="refCutaway" :text="data.preRevealCutawayText" />
    </Cutaway> -->
</template>

<script setup>
    import { useWindowMessage } from '#imports';
    import { noop } from '@vueuse/core';
    import { WebGLRenderTarget } from 'three';
    import {
        computed,
        onMounted,
        provide,
        reactive,
        ref,
        shallowRef,
        watch,
        watchEffect,
    } from 'vue';

    import { lerp, map } from '@resn/gozer-math';
    import { delay } from '@resn/gozer-misc';
    import {
        usePane,
        useRafBool,
        useRenderer,
        useThreeObject,
        useViewportResize,
    } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import CardInner from './CardInner.vue';
    import Cutaway from './reveals/modules/Cutaway/Cutaway.vue';
    import CutawayA from './reveals/modules/Cutaway/CutawayA.vue';
    import CutawayB from './reveals/modules/Cutaway/CutawayB.vue';
    import RevealsWrapper from './reveals/RevealsWrapper.vue';
    import Slab from './Slab.vue';
    import Vortex from './Vortex.vue';
    import { CardRevealComplete } from '~/api/constants/messages';
    import { Tier0, Tier1, Tier3, Tier4 } from '~/api/constants/tiers';
    import { BLOOM_INTENSITY, useBloom } from '~/composables/useBloom';
    import { useCardMotion } from '~/composables/useCardMotion';
    import { LAYER_CARD_INNER, LIGHTS_DOWN_DUR, LIGHTS_DOWN_REVEAL_START } from '~/core/constants';
    import { useAudio } from '~/providers/AudioProvider';
    import { useFanatics } from '~/providers/FanaticsProvider';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    onMounted(() => {
        window[`card${props.index}Progress`] = inProgress;
    });

    const props = defineProps({
        index: { default: 0 },
        active: { default: false },
        visible: { default: false },
        motionActive: { default: false },
        revealProgress: { default: 0 },
        revealAngle: { default: 0 },
        rotation: { default: 0 },
        flip: { default: false },
        revealed: { default: false },
        canReveal: { default: false },
        zoom: { default: false },
        colorPalette: { default: [] },

        data: {
            default: {
                id: 'card-1',
                imageFront: '/images/sample-cards/8-front@lg.webp',
                imageBack: '/images/sample-cards/8-back@lg.webp',
                title: 'Mike Trout',
                tier: Tier1,
                condition: 'Mint',
                serialNumber: 1,
                serialTotal: 99,
                gradeBadge: '/textures/card/psa-test@lg.webp',
                parallel: 'Long Parallel Text',

                playerName: {
                    first: 'Mike',
                    last: 'Trout',
                },
                playerNumber: 29,

                teamName: {
                    line1: 'Los Angeles',
                    line2: 'Angels',
                },
                teamBadge: '/textures/card/team-badge@lg.webp',
                teamYear: 2021,

                reveals: [
                    { type: 'card-info' },
                    // { type: 'player-info' },
                    // { type: 'team-info' },
                    // { type: 'cutaway-c' },
                    // { type: 'lights-down' },
                ],
            },
        },

        // for debugging
        color: { default: '#f00' },
    });

    const BASE_WIPE_ANGLE = 110;
    // const HIGH_TIER_WIPE_ANGLE = 275;
    const HIGH_TIER_WIPE_ANGLE = 90;
    const LIGHTS_DOWN_WIPE_ANGLE = 275;

    const { renderer, clock } = useRenderer();
    const fanatics = useFanatics();
    const bloom = useBloom();
    const audio = useAudio();

    const render = ref(false);
    const isVisible = ref(false);
    const motionActive = computed(() => props.motionActive);
    const interactive = ref(false);
    // const revealedCompute = computed(() => props.revealed);
    const revealedComplete = ref(false);
    const finalReveal = ref(false);

    const inActivePosition = ref(false);
    const isBaseTier = computed(() => props.data.tier === Tier1);
    const isHighTier = computed(() => !isBaseTier.value);
    const revealedCompute = computed(
        () => props.revealed || (props.canReveal && isBaseTier.value && inActivePosition.value)
    );
    // const canZoom = computed(
    //     () =>
    //         revealedComplete.value && (isBaseTier.value || props.zoom) && props.rotation % 180 === 0
    // );
    const canZoom = computed(
        () => finalReveal.value && (isBaseTier.value || props.zoom) && props.rotation % 180 === 0
    );
    const hasLightsDown = computed(() =>
        props.data.reveals.some((reveal) => reveal.type === 'lights-down')
    );

    const innerCardFbo = new WebGLRenderTarget();

    const time = ref(0);
    const revealPr = ref(0);
    const heartbeatPr = ref(0);
    const depthOffset = ref(0);
    const wipeAngle = ref(BASE_WIPE_ANGLE);
    const lightAngle = ref(0);
    const inProgress = ref(0);
    const position = reactive({ x: 0, y: 0, z: 0 });

    const { sendMessage } = useWindowMessage();
    const { object } = useThreeObject(null, { name: 'Card' });
    object.visible = false;

    const cardMotion = useCardMotion({
        object,
        motionActive,
        interactive,
        onHeartbeatRepeat: () => onHeartbeatRepeat(),
    });

    const refSlab = ref(null);
    const refReveals = ref(null);
    const refVortex = ref(null);
    const refCutawayWrapper = ref(null);
    const refCutaway = ref(null);
    const slabObject = shallowRef(null);
    const cardFrontObject = shallowRef(null);
    const cardBackObject = shallowRef(null);

    useGlobalAssets(({ data }) => {
        const card = data.card.scene.clone(true);
        card.scale.setScalar(0.5);

        cardMotion.finalObject.add(card);

        card.traverse((child) => {
            if (child.isMesh) {
                switch (child.name) {
                    case 'slab002':
                        slabObject.value = child;
                        break;

                    case 'front':
                        cardFrontObject.value = child;

                        // createBack
                        var back = child.clone(true);
                        back.name = 'back';
                        child.parent.add(back);
                        cardBackObject.value = back;

                        break;
                    case 'back':
                        break;

                    default:
                        break;
                }
            }
        });

        requestAnimationFrame(() => {
            reset();
            setActive(props.active);
            setVisible(props.visible);
            setRevealed(revealedCompute.value);
            setRender();
        });
    });

    const showPreRevealCutaway = () => {
        const tl = gsap.timeline();
        tl.add(() => refCutawayWrapper.value.show(), 0);
        tl.add(refCutaway.value.show(), 0);
        tl.add(() => refCutawayWrapper.value.hide(), '-=1.5');
    };

    let renderDelay;
    const setActive = (val) => {
        // console.log('🚀 ~ setActive ~ val:', props.index, val);

        renderDelay?.kill();
        sweatEffectDelay?.kill();
        heartbeatEffectDelay?.kill();
        cardMotion.stopHeartbeatEffect();

        if (val) {
            render.value = true;
            const tier = revealedCompute.value ? props.data.tier : Tier0;
            fanatics.setColorPalette(tier);

            const bloomIntensity = revealedCompute.value || isBaseTier.value ? 0 : BLOOM_INTENSITY;

            gsap.to(bloom.intensity, {
                value: bloomIntensity,
                duration: 1,
                delay: revealedCompute.value ? 0 : 0.2,
                ease: 'power1.inOut',
            });

            gsap.delayedCall(1.2, () => {
                inActivePosition.value = true;
            });

            if (revealedCompute.value) finalReveal.value = true;

            if (revealedCompute.value && fanatics.rerip.value) interactive.value = true;
            else {
                if (isHighTier.value) {
                    wipeAngle.value = HIGH_TIER_WIPE_ANGLE;
                    if (!revealedCompute.value) {
                        if (refCutawayWrapper.value) gsap.delayedCall(1, showPreRevealCutaway);
                        const heartbeatDelay = refCutawayWrapper.value ? 4 : 1;
                        heartbeatEffectDelay = gsap.delayedCall(
                            heartbeatDelay,
                            cardMotion.playHeartbeatEffect
                        );
                    }
                } else {
                    sweatEffectDelay = gsap.delayedCall(6, startSweatEffect);
                }
            }
        } else {
            // inActivePosition.value = false;
            interactive.value = false;

            renderDelay = gsap.delayedCall(1, () => {
                render.value = false;
            });
        }
    };

    const setVisible = (val) => {
        onUpdate({ timestamp: 0 });
    };

    const show = ({ delay = 0 } = {}) => {
        gsap.killTweensOf([lightAngle, inProgress]);
        gsap.to(inProgress, {
            value: 1,
            duration: 2,
            ease: 'power1.inOut',
            delay,
        });
        gsap.to(lightAngle, {
            value: 360,
            duration: 5,
            // ease: 'power1.inOut',
            delay,
            onComplete: () => {
                gsap.set(lightAngle, { value: 0 });
                gsap.to(lightAngle, { value: 360, duration: 10, ease: 'none', repeat: -1 });
            },
        });

        revealPr.value = isBaseTier.value ? 1 : 0;
    };

    const setRevealProgress = (val) => {
        if (revealedCompute.value) return;

        revealPr.value = map(val, 0, 1, 0, 0.75, true);
        bloom.intensity.value = map(val, 0, 0.2, BLOOM_INTENSITY, BLOOM_INTENSITY * 0, true);
        // revealPr.value = lerp(val, 0, 0.33); // remap the progress to only partially show the reveal
        // bloom.intensity.value = map(val, 0, 0.5, BLOOM_INTENSITY, BLOOM_INTENSITY * 0, true);
    };

    const setRevealAngle = (val) => {
        if (revealedCompute.value) return;

        wipeAngle.value = map(val, 0, 360, 90, 450, true);
    };

    const setRevealed = (val) => {
        // console.log('🚀 ~ setRevealed ~ val:', val);
        sweatEffectDelay?.kill();
        heartbeatEffectDelay?.kill();
        cardMotion.stopHeartbeatEffect();

        if (val) {
            if (props.active) {
                fanatics.setColorPalette(props.data.tier);
                const { reveals } = props.data;

                const showReveals =
                    refReveals.value &&
                    reveals.length &&
                    isHighTier.value &&
                    (props.revealProgress < 0.25 || hasLightsDown.value);

                if (showReveals) {
                    refReveals.value.show();

                    gsap.to(revealPr, { value: 0, duration: 0.5 });
                    gsap.to(bloom.intensity, { value: BLOOM_INTENSITY, duration: 0.5 });
                } else onRevealComplete();
            } else {
                revealPr.value = 1;
            }
        } else {
            interactive.value = false;
            revealPr.value = 0;
            revealedComplete.value = false;
            finalReveal.value = false;
        }
    };

    const onRevealComplete = () => {
        const tl = gsap.timeline({
            onComplete: () => {
                sendMessage(CardRevealComplete, props.index);
                fanatics.events.emit(CardRevealComplete, props.index);

                revealedComplete.value = true;

                if (fanatics.rerip.value) interactive.value = true;
            },
        });

        if (fanatics.lightsDown.value) {
            fanatics.lightsDown.value = false;
            finalReveal.value = true;
            return;
        }

        const revealDelay = fanatics.rerip.value ? 0 : 0;
        const wipeRevealDelay = revealDelay + 0.2;
        tl.add(() => audio.play('cardReveal'), 0.1);
        tl.add(() => (finalReveal.value = true), 0.25);

        if (isHighTier.value) {
            tl.add(() => refVortex.value?.show(), 0.1);
            tl.to(
                bloom.intensity,
                { value: 0, duration: 0.75, ease: 'sine.inOut' },
                wipeRevealDelay
            );
        }

        tl.to(revealPr, { value: 1, duration: 1.5, ease: 'sine.out' }, wipeRevealDelay);

        const type = isBaseTier.value ? 'spin' : null;
        tl.add(() => cardMotion.reveal(type), revealDelay);
    };

    const onRevealStepUpdate = (last) => {};

    const setHeartbeatPr = (val) => {
        // heartbeatPr.value = val;
    };

    const onHeartbeatRepeat = () => {
        const ease = 'power1.out';

        const delay = 0.05;
        const duration = 0.2;
        const delay2 = delay + duration + 0.1;
        gsap.killTweensOf(heartbeatPr);
        gsap.to(heartbeatPr, { value: 1, duration, delay, ease });
        gsap.to(heartbeatPr, { value: 0, duration: 1.5, delay: delay2, ease });

        audio.play('heartbeat');
    };

    const onStartLightsDown = () => {
        fanatics.lightsDown.value = true;

        const startWipeIn = LIGHTS_DOWN_REVEAL_START;
        const wipeInDur = 5;

        const tl = gsap.timeline({
            onComplete: () => {
                wipeAngle.value = BASE_WIPE_ANGLE;
            },
        });

        tl.add(refSlab.value.startLightsDown(), 0);
        tl.add(() => (wipeAngle.value = LIGHTS_DOWN_WIPE_ANGLE), 1);

        tl.add(cardMotion.startLightsDownReveal({ setDelay: 1, startDelay: startWipeIn }), 0);
        tl.to(revealPr, { value: 1, duration: wipeInDur, ease: 'power2.out' }, startWipeIn);
        tl.to(bloom.intensity, { value: 0, duration: 1.5, ease: 'sine.inOut' }, startWipeIn);

        tl.add(noop, LIGHTS_DOWN_DUR);
    };

    const hide = () => {
        cardMotion.hide();
    };

    const setRender = () => {
        object.visible = render.value;
    };

    const onUpdate = ({ timestamp }) => {
        cardMotion.update(timestamp);

        time.value = clock.getElapsedTime();

        depthOffset.value = map(object.position.z, -3, 0, 1, 0, true);
        isVisible.value = object.position.z > -3 && object.position.y > -3;
    };

    let sweatEffectDelay = null;
    const startSweatEffect = () => {
        sweatEffectDelay?.kill();

        if (revealedCompute.value) return;

        cardMotion.playSweatEffect();

        sweatEffectDelay = gsap.delayedCall(10, startSweatEffect);
    };

    let heartbeatEffectDelay = null;

    const setRotation = (val) => {
        cardMotion.setRotation(val);

        if (props.motionActive) audio.play('cardRotate');
    };
    const setFlip = (val) => {
        cardMotion.setFlip(val);

        if (props.motionActive) audio.play('cardFlip');
    };
    const setZoom = (val) => {
        cardMotion.setZoom(val);
        // if (props.motionActive) audio.play('cardZoom');
    };

    const reset = () => {
        setRotation(false);
        setFlip(0);
        setZoom(canZoom.value);

        revealPr.value = 0;
        inProgress.value = 0;
        lightAngle.value = 0;
        revealedComplete.value = false;

        cardMotion.reset();
    };

    useRafBool(render, onUpdate);

    useViewportResize(({ width, height }) => {
        const dpr = renderer.getPixelRatio();
        innerCardFbo.setSize(width * dpr, height * dpr);
    }, true);

    provide('card', {
        inProgress,
        revealPr,
        heartbeatPr,
        depthOffset,
        wipeAngle,
        lightAngle,
        time,
    });

    watch(render, setRender);
    watch(() => props.visible, setVisible);
    watch(() => props.active, setActive);
    watch(revealedCompute, setRevealed);

    watch(() => props.rotation, setRotation);
    watch(() => props.flip, setFlip);
    watch(() => props.revealProgress, setRevealProgress);
    watch(() => props.revealAngle, setRevealAngle);
    // watch(() => props.zoom, setZoom);
    watch(canZoom, setZoom);
    watch(heartbeatPr, setHeartbeatPr);

    const options = { min: 0, max: 1, step: 0.01 };

    const pos = ref({ x: 0, y: 0, z: 0 });
    watchEffect(() => {
        object.position.set(pos.value.x, pos.value.y, pos.value.z);
    });

    usePane(
        [
            { name: 'pos', value: pos },
            { name: 'revealPr', value: revealPr, options },
            { name: 'inProgress', value: inProgress, options },
            {
                name: 'wipeAngle',
                value: wipeAngle,
                options: { min: 0, max: 360, step: 0.1 },
            },
            {
                name: 'lightAngle',
                value: lightAngle,
                options: { min: 0, max: 360, step: 0.1 },
            },
            {
                name: 'position',
                value: position,
            },
        ],
        { title: 'Card', subfolder: false }
    );

    defineExpose({
        show,
        hide,
        reset,
        object,
    });
</script>
